var routers = [
        {
                //考勤统计
                path: '/business/workAttendance/list',
                name: 'BusinessWorkAttendanceList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/business/workAttendance-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '考勤统计'
                }
        },
        {
                //补卡申请记录
                path: '/business/fillAttendance/list',
                name: 'business-fillAttendance-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/business/fillAttendance-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '补卡申请记录'
                }
        },
        {
                //学生请假记录
                path: '/business/requestForLeave/list',
                name: 'business-requestForLeave-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/business/requestForLeave-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '学生请假记录'
                }
        },
        {
                path: '/business/getPoints/list',
                name: 'business-getPoints-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/business/getPoints-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '积分获取记录'
                }
        },
        {
                path: '/business/redeemPoints/list',
                name: 'business-redeemPoints-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/business/redeemPoints-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '积分兑换记录'
                }
        },
        {
                path: '/business/mobileRechargeRecord/list',
                name: 'business-mobileRechargeRecord-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/business/mobileRechargeRecord-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '移动充值办理台账'
                }
        },
        {
                path: '/business/cameraLiveRadio/list',
                name: 'businessCameraLiveRadioList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/business/cameraLiveRadio-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '视频探宝'
                }
        },
        {
                path: '/business/personPoints/list',
                name: 'BusinessPersonPointsList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/business/personPoints-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '用户积分统计表'
                }
        },{
                path: '/business/examInfo/list',
                name: 'businessExamInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/business/examInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '学生成绩单'
                } 
        }
]

export default routers;