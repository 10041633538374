var routers = [
        {
                //学生管理
                path: '/student/studentInfo/list',
                name: 'StudentInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/student/studentInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '学生管理'
                }
        },
        {
                //学生每日健康情况
                path: '/student/personHealthLedger/list',
                name: 'StudentPersonHealthLedgerList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/student/personHealthLedger-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '学生健康打卡情况'
                }
        },
        {
                //学生每日健康情况-包含健康打卡和上报
                path: '/student/personHealthLedger/listAll',
                name: 'StudentPersonHealthLedgerListAll',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/student/personHealthLedger-list-all.vue'),
                meta: {
                        roles: ["admin"],
                        title: '学生测温台账'
                }
        },
]

export default routers;