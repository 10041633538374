/*
        router中name必须与组件的name一致，才能实现keeplive标签下正常缓存组件
*/
var routers = [
    {
            path: '/merchantClient/home',
            name: 'merchantClientHome',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('@/views/merchantClient/home.vue'),
            meta: {
                    roles: ["admin"],
                    title: '控制台'
            }
    },
    {
        //订单管理
        path: '/merchantClient/order/list',
        name: 'merchantClientOrderList',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/merchantClient/order-list.vue'),
        meta: {
                roles: ["admin"],
                title: '订单管理'
        }
    },
    {
        //商家提现记录
        path: '/merchantClient/merchantApplicationRecord/list',
        name: 'merchantClientMerchantApplicationRecordList',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/merchantClient/merchantApplicationRecord-list.vue'),
        meta: {
                roles: ["admin"],
                title: '商家提现记录'
        }
    }
]

export default routers;