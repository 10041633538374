var routers = [
        {
                path: '/interest/course/list',
                name: 'interestCourseList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/interest/interestCourse-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '兴趣社团课程管理'
                }
        }, {
                path: '/interest/class/list',
                name: 'interestClassList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/interest/interestClass-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '兴趣社团管理'
                }
        }, {
                path: '/interest/register/list',
                name: 'interestRegisterList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/interest/interestRegister-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '兴趣社团报名管理'
                }
        }
]

export default routers;