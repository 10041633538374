/*
        router中name必须与组件的name一致，才能实现keeplive标签下正常缓存组件
*/
var routers = [
        {
                path: '/base/terminalInfo/list',
                name: 'base-terminalInfo-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/terminalInfo-list.vue'),
                meta: {
                        roles: ["admin"]
                }
        },
        {
                path: '/base/electricClientInfo/list',
                name: 'base-electricClientInfo-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/electricClientInfo-list.vue'),
                meta: {
                        roles: ["admin"]
                }
        },
        {
                path: '/base/electricMeterInfo/list',
                name: 'base-electricMeterInfo-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/electricMeterInfo-list.vue'),
                meta: {
                        roles: ["admin"]
                }
        },
        {
                path: '/base/rechargeRecord/list',
                name: 'base-rechargeRecord-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/rechargeRecord-list.vue'),
                meta: {
                        roles: ["admin"]
                }
        },
        {
                path: '/base/rechargeRecordWater/list',
                name: 'base-rechargeRecordWater-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/rechargeRecordWater-list.vue'),
                meta: {
                        roles: ["admin"]
                }
        },
        {
                path: '/base/rechargeRecordProperty/list',
                name: 'base-rechargeRecordProperty-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/rechargeRecordProperty-list.vue'),
                meta: {
                        roles: ["admin"]
                }
        },
        {
                path: '/base/roomInfo/list',
                name: 'base-roomInfo-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/roomInfo-list.vue'),
                meta: {
                        roles: ["admin"]
                }
        },
        {
                path: '/base/rechange/list',
                name: 'base-rechange-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/rechange-list.vue'),
                meta: {
                        roles: ["admin"]
                }
        },
        {
                path: '/base/ownerInfo/list',
                name: 'base-ownerInfo-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/ownerInfo-list.vue'),
                meta: {
                        roles: ["admin"]
                }
        },
        {
                path: '/base/parkingInfo/list',
                name: 'base-parkingInfo-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/parkingInfo-list.vue'),
                meta: {
                        roles: ["admin"]
                }
        },
        {
                path: '/base/parkingApply/list',
                name: 'base-parkingApply-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/parkingApply-list.vue'),
                meta: {
                        roles: ["admin"]
                }
        },
        {
                path: '/base/alarmInfoFence/list',
                name: 'base-alarmInfoFence-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/alarmInfo-fence-list.vue'),
                meta: {
                        roles: ["admin"]
                }
        },
        {
                path: '/base/alarmInfoAlert/list',
                name: 'base-alarmInfoAlert-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/alarmInfo-alert-list.vue'),
                meta: {
                        roles: ["admin"]
                }
        },
        {
                path: '/base/informationInfoComplaint/list',
                name: 'base-informationInfoComplaint-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/informationInfo-complaint-list.vue'),
                meta: {
                        roles: ["admin"]
                }
        },
        {
                path: '/base/informationInfoWarranty/list',
                name: 'base-informationInfoWarranty-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/informationInfo-warranty-list.vue'),
                meta: {
                        roles: ["admin"]
                }
        },
        {
                path: '/base/employeeInfo/list',
                name: 'base-employeeInfo-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/employeeInfo-list.vue'),
                meta: {
                        roles: ["admin"]
                }
        },
        {
                //单位管理
                path: '/base/companyInfo/list',
                name: 'BaseCompanyInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/companyInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '单位管理'
                }
        },
        {
                //人员管理
                path: '/base/personInfo/list',
                name: 'BasePersonInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/personInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '教职工管理'
                }
        },
        {
                //设备管理
                path: '/base/deviceInfo/list',
                name: 'BaseDeviceInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/deviceInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '设备管理'
                }
        },
        {
                //台账信息
                path: '/base/personDeviceLog/list',
                name: 'BasePersonDeviceLogList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/personDeviceLog-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '测温记录'
                }
        },
        {
                //检测预警上报人员管理
                path: '/base/warningPusher/list',
                name: 'BaseWarningPusherList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/warningPusher-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '预警上报人员管理'
                }
        },
        {
                //通知公告
                path: '/base/messageNotice/list',
                name: 'BaseMessageNoticeList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/messageNotice-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '通知公告'
                }
        },
        {
                //异常上报
                path: '/base/messageReport/list',
                name: 'BaseMessageReportList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/messageReport-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '异常上报'
                }
        },
        {
                //打卡时间
                path: '/base/alarmConfig/list',
                name: 'BaseAlarmConfigList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/alarmConfig-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '考勤时间设置'
                }
        },
        {
                //测温统计
                path: '/base/temperatureRecord/list',
                name: 'BaseTemperatureRecordList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/temperatureRecord-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '测温统计'
                }
        },
        {
                //设备人员管理
                path: '/base/devicePerson/list',
                name: 'BaseDevicePersonList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/devicePerson-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '设备人员管理'
                }
        },
        {
                //节假日设置
                path: '/base/holidayInfo/list',
                name: 'BaseHolidayInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/holidayInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '节假日设置'
                }
        },
        {
                //教职工健康台账设置
                path: '/base/teacherHealthLedger/list',
                name: 'BaseTeacherHealthLedgerList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/teacherHealthLedger-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '职工健康打卡情况'
                }
        },
        {
                //教职工健康台账设置
                //包含测温和健康上报
                path: '/base/teacherHealthLedger/listAll',
                name: 'BaseTeacherHealthLedgerListAll',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/teacherHealthLedger-list-all.vue'),
                meta: {
                        roles: ["admin"],
                        title: '职工测温台账'
                }
        },
        {
                //校园场景值管理设置
                path: '/base/sceneCompany/list',
                name: 'BaseSceneCompanyList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/sceneCompany-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '校园场景值管理设置'
                }
        },
        {
                //打卡点管理
                path: '/base/punchLocation/list',
                name: 'BasePunchLocationList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/punchLocation-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '打卡点管理'
                }
        },
        {
                //打卡点打卡记录
                path: '/base/punchRecord/list',
                name: 'BasePunchRecordList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/punchRecord-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '打卡点打卡记录'
                }
        },
        {
                //测温台账
                path: '/base/personDeviceLog/punchList',
                name: 'BasePersonDeviceLogPunchList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/personDeviceLog-punchList.vue'),
                meta: {
                        roles: ["admin"],
                        title: '测温台账'
                }
        },
        {
                //订单管理
                path: '/base/order/list',
                name: 'BaseOrderList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/order-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '订单管理'
                }
        },
        {
                //积分
                path: '/base/pointsInfo/list',
                name: 'BasePointsInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/pointsInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '积分管理'
                }
        },
        {
                //学校微信图标管理
                path: '/base/schoolServe/list',
                name: 'BaseSchoolServeList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/schoolServe-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '学校微信图标管理'
                }
        },
        {
                path: '/base/mobileBannerInfo/list',
                name: 'baseMobileBannerInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/mobileBannerInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '移动端广告栏位管理'
                }
        },
        {
                path: '/base/graduateClassInfo/list',
                name: 'baseGraduateClassInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/graduateClassInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '已毕业班级管理'
                }
        },
        {
                path: '/base/cameraInfo/list',
                name: 'baseCameraInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/cameraInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '摄像头管理'
                }
        },
        {
                path: '/base/schoolStat/list',
                name: 'baseSchoolStatList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/schoolStat-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '学校测温统计'
                }
        },
        {
                path: '/base/goodsInfo/list',
                name: 'BaseGoodsInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/goodsInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '商品管理'
                }
        },
        {
                path: '/base/canteenMenu/list',
                name: 'baseCanteenMenuList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/canteenMenu-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '菜谱管理'
                }
        },
        {
                path: '/base/activityInfo/list',
                name: 'BaseActivityInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/activityInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '活动管理'
                }
        },
        {
                path: '/base/order/orderHome',
                name: 'baseOrderHome',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/order-home.vue'),
                meta: {
                        roles: ["admin"],
                        title: '订单控制台',
                        affix: true
                }
        },
        {
                path: '/base/schoolTerm/list',
                name: 'BaseSchoolTermList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/schoolTerm-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '学期管理'
                }
        },
        {
                path: '/base/newsInfo/list',
                name: 'BaseNewsInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/newsInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '新闻管理'
                }
        },
        {
                path: '/base/orderShareRecord/list',
                name: 'baseOrderShareRecordList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/orderShareRecord-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '分润收益记录'
                }
        },
        {
                path: '/base/orderWithdrawalRecord/list',
                name: 'baseOrderWithdrawalRecordList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/orderWithdrawalRecord-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '提现记录'
                }
        },
        {
                path: '/base/classNewsInfo/list',
                name: 'baseClassNewsInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/classNewsInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '班级新闻管理'
                }
        },
        {
                path: '/base/inspection/list',
                name: 'baseInspectionList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/inspection-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '晨午检'
                }
        },
        {
                path: '/base/inspectionConfig/list',
                name: 'baseInspectionConfigList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/inspectionConfig-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '晨午检时间设置'
                }
        },
        {
                path: '/base/schoolCommissioner/list',
                name: 'baseSchoolCommissionerList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/schoolCommissioner-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '健康打卡日报'
                }
        },
        {
                path: '/base/loudspeakerInfo/list',
                name: 'baseLoudspeakerInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/loudspeakerInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '喇叭管理'
                }
        },
]

export default routers;