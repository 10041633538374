<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: "app",
  data() {
    return {};
  },
  methods: {
    
  }
};
</script>
<style rel="stylesheet/scss" lang="scss">
body{
  margin: 0px;
}

#app {
  margin: 0px;
  padding: 0px;
  background: #efefef;
  position:absolute;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  display:flex;
  flex-direction: column;
}

.sticky-bg{
  background-color:#fff;
}

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand .el-form-item__label {
  width: 90px;
  color: #2f68d1 !important;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

.el-table__header thead tr th{
  background: #f1f6fa !important;
  padding: 10px 0px 10px 0px;
}

.el-table{
  margin-top:5px;
}

.demo-form-inline .el-form-item {
    margin-bottom: 10px !important;
}
</style>
