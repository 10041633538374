import request from '@/utils/request'
import constant from '@/constant'

function statList(formData){
  return request.post(constant.serverUrl + "/business/workAttendance/statList", formData);
}

function exportXls(formData){
  return request.post(constant.serverUrl + "/business/workAttendance/exportXls", formData);
}

function getFirstData(){
  return request.get(constant.serverUrl + "/home/getFirstData");
}

function getFirstExcelData(){
  return request.get(constant.serverUrl + "/home/getFirstExcelData");
}

function batchUpdate(formData){
  return request.post(constant.serverUrl + "/business/workAttendance/batchUpdate", formData);
}

export default {
  statList,exportXls,getFirstData,getFirstExcelData,batchUpdate
}