import request from '@/utils/request'
import constant from '@/constant'

function list(obj) {
  var formData = new FormData();

  for(var key in obj){
    formData.append(key,obj[key]);
  }
  
  return request.post(constant.serverUrl + "/base/companyInfo/list",formData);
}

function treeList() {
  return request.post(constant.serverUrl + "/base/companyInfo/treeList");
}

function pageList(formData) {
  return request.post(constant.serverUrl + "/base/companyInfo/pageList", formData);
}

function loadChildren(formData) {
  return request.post(constant.serverUrl + "/base/companyInfo/children",formData);
}

function create() {
  return request.get(constant.serverUrl + "/base/companyInfo/create");
}

function edit(id) {
  return request.get(constant.serverUrl + "/base/companyInfo/edit/" + id);
}

function add(formModel) {
  return request.post(constant.serverUrl + "/base/companyInfo/add", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel) {
  return request.post(constant.serverUrl + "/base/companyInfo/update", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id) {
  return request.post(constant.serverUrl + "/base/companyInfo/delete/" + id);
}

function batchRemove(idList) {
  return request.post(constant.serverUrl + "/base/companyInfo/batchDelete", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function queryBindDeviceList(formData) {
  var objData = {};
  formData.forEach((value, key) => objData[key] = value);
  return request.get(constant.serverUrl + "/base/companyInfo/queryBindDeviceList", {
    params: objData,
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function bindDevice(formData) {
  return request.post(constant.serverUrl + "/base/companyInfo/bindDevice", formData);
}

function unbindDevice(formData) {
  return request.post(constant.serverUrl + "/base/companyInfo/unbindDevice", formData);
}

function upgradeClass(dtoList) {
  return request.post(constant.serverUrl + "/base/companyInfo/upgradeClass", dtoList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function listOfSchoolByMerchantId() {
  return request.post(constant.serverUrl + "/base/companyInfo/listOfSchoolByMerchantId");
}


function graduateClass(dtoList) {
  return request.post(constant.serverUrl + "/base/companyInfo/graduateClass", dtoList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export default {
  list, treeList,create, edit, add, update, remove, batchRemove, pageList, queryBindDeviceList,
  bindDevice, unbindDevice,loadChildren,upgradeClass,graduateClass,listOfSchoolByMerchantId
}