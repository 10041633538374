/*
        router中name必须与组件的name一致，才能实现keeplive标签下正常缓存组件
*/
var routers = [
        {
                path: '/integral/integralGoodsInfo/list',
                name: 'integralGoodsInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/integral/integralGoodsInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '积分商品管理'
                }
        },
        {
                path: '/integral/integralExchangeInfo/list',
                name: 'integralExchangeInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/integral/integralExchangeInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '商品兑换统计'
                }
        },
        {
                path: '/integral/integralDetailInfo/list',
                name: 'integralDetailInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/integral/integralDetailInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '用户积分统计'
                }
        },
        {
                path: '/integral/integralActivityInfo/list',
                name: 'integral-activityInfo-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/integral/integralActivityInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '打卡活动管理'
                }
        }
]

export default routers;