import Vue from 'vue'
import App from './App.vue'
import router from './routers'
import store from './store'
import echarts from 'echarts'
import './plugins/element.js'
import AxiosPlugin from './plugins/AxiosPlugin'
import './assets/iconfont/iconfont.css'
import { Message } from 'element-ui'
// import VueQuillEditor from 'vue-quill-editor';
// import 'quill/dist/quill.core.css';
// import 'quill/dist/quill.snow.css';
// import 'quill/dist/quill.bubble.css';
// import BaiduMap from 'vue-baidu-map'
import AMap from "vue-amap";
import Sticky from './components/Sticky'

Vue.component('custom-sticky',Sticky);
//import ConfirmPlugin from '@/plugins/ConfirmPlugin'

Vue.config.productionTip = false
Vue.use(AxiosPlugin);
// Vue.use(VueQuillEditor);

//Vue.use(ConfirmPlugin);

// Vue.use(BaiduMap, {
//   ak: process.env.BAIDU_MAP_KEY
// })
Vue.use(AMap);

AMap.initAMapApiLoader({
  key: "09abadb2e35fc61fd84042c518e9aebf", 
  plugin: ["AMap.Scale", "AMap.OverView", "AMap.ToolBar", "AMap.MapType"],
  uiVersion: "1.0.11", // ui库版本，不配置不加载,
  v: "1.4.4"
});

Vue.prototype.$echarts = echarts

var MY_MESSAGE_DEFAULT = {
  showClose: true
}
 
// Vue.prototype.$message = function (options) {
//   if (typeof (options) === 'object') {
//     options = Object.assign({}, MY_MESSAGE_DEFAULT, options)
//   }

//   return Message(options)
// }

var MessageWrapper = function (options) {
  if (typeof (options) === 'object') {
    options = Object.assign({}, MY_MESSAGE_DEFAULT, options)
  }

  return Message(options)
}

for(var key in Message){
  MessageWrapper[key] = Message[key]
}

Vue.prototype.$message = MessageWrapper

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
