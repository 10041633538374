var routers = [
        {
                //线路管理
                path: '/bus/route/list',
                name: 'busRouteList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/bus/route-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '线路管理'
                }
        },
        {
                //校车管理
                path: '/bus/vehicle/list',
                name: 'busVehicleList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/bus/vehicle-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '校车管理'
                }
        },
        {
                //报名缴费管理
                path: '/bus/registrationFee/list',
                name: 'busRegistrationFeeList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/bus/registrationFee-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '报名缴费管理'
                }
        },
        {
                path: '/bus/schedule/list',
                name: 'busScheduleList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/bus/schedule-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '班次管理'
                }
        },
        {
                path: '/bus/vehiclePeriod/list',
                name: 'busVehiclePeriodList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/bus/vehiclePeriod-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '学年度管理'
                }
        },
        {
                path: '/bus/detentionWarning/list',
                name: 'busDetentionWarningList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/bus/detentionWarning-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '预警记录'
                }
        },
        {
                path: '/bus/travelDailyLog/list',
                name: 'busTravelDailyLogList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/bus/travelDailyLog-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '学生乘车记录'
                }
        },
        {
                path: '/bus/irisCourseStudent/list',
                name: 'busIrisCourseStudentList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/bus/irisCourseStudent-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '虹膜登记缴费记录'
                }
        },
        {
                path: '/bus/schoolCourse/list',
                name: 'busSchoolCourseList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/bus/schoolCourse-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '在校课程管理'
                }
        },
        {
                path: '/bus/schoolBusHandover/list',
                name: 'busSchoolBusHandoverList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/bus/schoolBusHandover-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '校车交接记录'
                }
        }
]

export default routers;