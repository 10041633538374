var routers = [
    {
            path: '/onlineClass/courseInfo/list',
            name: 'online-class-course-list',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('@/views/onlineClass/courseInfo-list.vue'),
            meta: {
                    roles: ["admin"],
                    title: '课程管理'
            }
    }
]

export default routers;