/*
        router中name必须与组件的name一致，才能实现keeplive标签下正常缓存组件
*/
var routers = [
        {
                path: '/merchant/goodsShareInfo/companyList',
                name: 'merchantGoodsShareInfoCompanyList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/merchant/goodsShareInfo-companyList.vue'),
                meta: {
                        roles: ["admin"],
                        title: '收费项目分润管理'
                }
        },
        {
            //商家管理
            path: '/merchant/merchantInfo/list',
            name: 'merchantMerchantInfoList',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('@/views/merchant/merchantInfo-list.vue'),
            meta: {
                    roles: ["admin"],
                    title: '商家管理'
            }
        },
        {
                //商家提现记录
                path: '/merchant/merchantApplicationRecord/list',
                name: 'merchantMerchantApplicationRecordList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/merchant/merchantApplicationRecord-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '商家提现记录'
                }
            }

]

export default routers;
